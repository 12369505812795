// nyweb10 fullscreen-media.less



/*
Styles used to get full-screen image or video on article-type pages.
Sticky effect if caption present. Js-detected.
Solution is not production-ready.
Requires editor-functionality for adding image/video of this type.
*/



/*
Todo for UiO/Museums
- Replace manual breakpoints with suitable uio variables.
- Check that calculation of left pos at viewport 0 works with uio framework.
*/



video {
  max-width: 100%;
}



// excluding admin preview due to several styles not working well there,
// such as vh units and fixed positioning

body:not(.vrtx-mode-preview) {

  &#vrtx-structured-article,
  &#vrtx-structured-event {

    // prevent 100vw images from adding horizontal scrollbar on windows
    overflow-x: hidden;

    .vrtx-article-body .media-fullscreen {
      img,
      video {
        @media (min-width: 1071px) {
          object-fit: cover;
          max-width: none;
          width: 100vw;
          height: 100vh;
        }

        // calculate left pos at viewport 0

        --main-indent: -220px;
        --article-body-indent: -155px; // calc value seems to be constant within breakpoints used here
        --viewport-indent: calc((100vw - 1440px)/-2);

        @media (max-width: 1179px) and (min-width: 1071px) {
          margin-left: var(--main-indent);
        }
        @media (min-width: 1180px) and (max-width: 1440px) {
          margin-left: calc(var(--main-indent) + var(--article-body-indent));
        }
        @media (min-width: 1441px) {
          margin-left: calc(var(--main-indent) + var(--article-body-indent) + var(--viewport-indent));
        }
      }

      // with caption and sticky effect

      &.has-caption {
        @media (min-width: 1071px) {
          figure {
            position: relative;

            // double viewport height for sticky scroll
            height: 200vh;

            // video-component. revert from relative to follow sticky behaviour
            .vrtx-background-video {
              position: static;
            }
          }
          figcaption {
            @media (min-width: 1071px) {
              position: absolute;
              z-index: 2;
              left: 20vw;
              top: 50%;
              background: white;
              padding: 40px;
              font-size: inherit;
              color: inherit;
              line-height: inherit;
              width: 500px;
            }
          }
          // js-detected on scroll
          &.sticky {
            img,
            video {
              position: fixed;
              top: 0;
              z-index: 1;
            }
            .pause-video {
              position: fixed;
              left: auto;
              z-index: 1;
            }
          }
          // js-detected on scroll
          &.scrolled-past {
            img,
            video {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }
    }
  }
}
